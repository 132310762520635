import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo/SEO"
import styled from "styled-components"

const SEOContainer = styled.div`
  justify-content: center;
  margin-top: 96px;
  background-color: #f5edff;
  min-height: calc(100vh - 104px);
  padding: 0rem 2rem;

  a {
    color: #8a47ff !important;
    font-weight: 400;
  }

  @media (min-width: ${props => props.theme.sizes.mobile}) {
    margin-top: 64px;
  }
`

const NotFoundPage = () => (
  <SEOContainer>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <p>
      Go back to&nbsp;
      <Link to="/">Home Page</Link>.
    </p>
  </SEOContainer>
)

export default NotFoundPage
